<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-text label="Name" :maxlength="64" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Status" v-model="model.status" :validation="$v.model.status" :options="$constants.registration.category.statusName" :descriptions="$constants.registration.category.statusDescription" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Visibility" v-model="model.applicable.enabled" :validation="$v.model.applicable.enabled" :options="$constants.registration.category.restrictName" :descriptions="$constants.registration.category.restrictDescription" />
			<app-input-toggle v-if="model.applicable.enabled" label="Eligible Badges" :column="true" :asString="true" :multiple="true" v-model="model.applicable.badges" :validation="$v.model.applicable.badges" :options="badgeOptions" />
			<app-input-image label="Image" placeholder="Enter image..." v-model="model.image" :validation="$v.model.image" />
			<app-input-date :time="true" label="Open from" placeholder="Enter date..." :toggle="model.from.enabled" v-on:toggle="model.from.enabled = !model.from.enabled" v-model="model.from.date" :validation="$v.model.from.date" />
			<app-input-date :time="true" label="Open until" placeholder="Enter date..." :toggle="model.to.enabled" v-on:toggle="model.to.enabled = !model.to.enabled" v-model="model.to.date" :validation="$v.model.to.date" />
			
		</app-content-box>

		<app-content-box :tabs="tabs" :readonly="$authorised('con/registration/access', 'read')">

			<template v-slot:items>

				<app-input-items v-model="model.items" :validation="$v.model.items" placeholder="Add item" api="convention/registration/item/all" />

			</template>

			<template v-slot:description>

				<app-input-content placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />

			</template>
			
			<template v-slot:other>

				<app-input-image label="Map" placeholder="Enter image..." v-model="model.map" :validation="$v.model.map" />

			</template>
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			tabs: [
				{ name: 'items', text: 'Items' },
				{ name: 'description', text: 'Description' },
				{ name: 'other', text: 'Other' }
			],
			readonly: this.$authorised('con/registration/access', 'read'),
			references: {
				badges: []
			},
			statusOptions: {
				1: 'Online',
				0: 'Offline'
			},
			model: {
				id: false,
				name: '',
				applicable: {
					enabled: 0,
					badges: []
				},
				status: 0,
				items: [],
				description: '',
				image: '',
				map: '',
				from: {
					enabled: false,
					date: 0
				},
				to: {
					enabled: false,
					date: 0
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			applicable: {},
			from: {},
			to: {}
		}
	},

	computed: {

		badgeOptions: function() {

			var options = []

			this.$_.each(this.references.badges, function(badge) {
				
				options.push({
					value: badge.id,
					text: badge.name
				})

			})

			return options

		}

	}

}

</script>

<style scoped>

</style>